const faqs = [
  {
    question: "1. ¿Es obligatorio el seguro de auto para viajar a Argentina?",
    answer: (
      <div>
        <p>
          {" "}
          Sí, para ingresar a Argentina con tu vehículo, es obligatorio contar
          con un seguro de auto que tenga cobertura internacional. Esto incluye
          la responsabilidad civil y puede requerir otras coberturas específicas
          según la legislación argentina.
        </p>
      </div>
    ),
  },
  {
    question: "2. ¿Cuál es el valor de un seguro de viaje a Argentina?",
    answer: (
      <div>
        <p>
          {" "}
          El costo del seguro de viaje puede variar según la cobertura
          seleccionada, la duración del viaje y la edad del viajero. En general,
          puedes encontrar opciones desde los $10 USD por día, pero es
          recomendable cotizar varias opciones para encontrar la mejor oferta.
        </p>
      </div>
    ),
  },
  {
    question: "3. ¿Cuáles son los beneficios del seguro de salud de viaje?",
    answer: (
      <div>
        <p>
          Un seguro de salud de viaje te protege ante imprevistos médicos,
          ofreciéndote cobertura para consultas, hospitalizaciones, medicamentos
          y repatriación sanitaria. Además, muchas pólizas incluyen asistencia
          24/7 y cobertura en caso de enfermedades preexistentes.
        </p>
      </div>
    ),
  },
  {
    question:
      "4. ¿Qué aseguradoras ofrecen los seguros de viaje más baratos para Argentina?",
    answer: (
      <div>
        <p>
          En Chile, existen muchas aseguradoras reconocidas, pero una de las
          mejores alternativas para viajar a Argentina es ASSIST 365.
        </p>
      </div>
    ),
  },
];

export default faqs;
